import enUS from 'i18n/en.json';
import deDE from 'i18n/de.json';

export type SettingsLocales = 'en-US' | 'de';

type SettingsLink = {
  name: string;
  href: string;
  items?: SettingsLink[];
};

export type Dictionary = {
  shortName: string;
  searchPlaceholder: string;
  searchBtn: string;
  cancel: string;
  searchResultSearchForTitle: string;
  searchResultResultsFor: string;
  searchResultNoResults: string;
  searchShowAll: string;
  settingsTheme: string;
  settingsPrint: string;
  settingsThemeLight: string;
  settingsThemeDark: string;
  settingsThemeHighContrast: string;
  settingsLanguage: string;
  breadcrumbHomeName: string;
  login: string;
  logout: string;
  start: string;
  continue: string;
  minsToReadMin: string;
  minsToReadHour: string;
  overview: string;
  learningPath: string;
  modules: string;
  module: string;
  units: string;
  unit: string;
  modulesInLearningPath: string;
  prerequisites: string;
  noPrerequisites: string;
  learningObjectives: string;
  learningObjectivesBody: string;
  nextUnit: string;
  next: string;
  previous: string;
  of: string;
  profile: string;
  settings: string;
  quizValidationAllFieldsAreRequired: string;
  quizCheckAnswers: string;
  quizReviewAnswers: string;
  moduleIncomplete: string;
  goBackToFinish: string;
  finishModule: string;
  moduleComplete: string;
  unlockAchievement: string;

  accountsTrainingNavLabel: string;
  accountsTrainingHeader: string;
  accountsCertificatesNavLabel: string;
  accountsCertificatesHeader: string;
  accountsBookmarksNavLabel: string;
  accountsBookmarksHeader: string;
  accountsSettingsNavLabel: string;
  accountsSettingsHeader: string;
  accountsModuleCompletedMessage: string;
  accountsModuleUncompletedMessage: string;
  accountsItemCompleted: string;

  assessmentSignInToStart: string;
  assessmentStartBtn: string;
  assessmentContinueBtn: string;
  assessmentAlreadyClaimed: string;
  assessmentDidntPassBtn: string;
  assessmentPassedHeadline: string;
  assessmentPassedBody: string;
  assessmentClaimFormModalBtn: string;
  assessmentCongratsModalHeadline: string;
  assessmentCongratsModalHeadlineExclamation: string;
  assessmentCongratsModalBody: string;
  assessmentClaimModalHeadline: string;
  assessmentClaimModalBody: string;
  assessmentClaimModalBody2: string;
  assessmentClaimFormEmailLabel: string;
  assessmentClaimFormEmailPlaceholder: string;
  assessmentClaimFormSubmitBtn: string;
  assessmentClaimFormValidationError: string;

  ratingRateBtn: string;
  ratingRateSignInToRate: string;
  ratingRateModalHeadline: string;
  ratingRateModalBody: string;
  ratingRateModalBtn: string;
  ratingYouHaveAlready: string;

  bookmarkBtn: string;
  bookmarkSavedBtn: string;
  bookmarkSignInToBookmark: string;
  bookmarkModalHeadline: string;
  bookmarkSaveBtn: string;
  bookmarkRemoveModalHeadline: string;
  bookmarkRemoveBtn: string;
  rateTextFieldPlaceholder: string;
  exploreOtherModulesAndLearningPaths: string;
  assessmentPassedDone: string;
  assessmentTheWorldKnow: string;
  notNow: string;
  wellDone: string;
  soClose: string;
  notQuite: string;
  completedKnowledgeCheck: string;
  beforeContinue: string;
  submit: string;
  helpUsImprove: string;
  nextModule: string;
  nextModuleContinue: string;
  learningPathOverview: string;
  modulePartOf: string;
  continueLearning: string;
  exploreOtherModules: string;
  keepUp: string;
  assessmentPassedAndClaimed: string;
  assessmentPassedNowClaim: string;
  pleaseTryAgain: string;
  pleaseTryAgainPercentage: string;
  congratulationsPassedAssessment: string;
  answeredCorrectly: string;
  goodLuck: string;
  getYourCertificate: string;
  youMustAnswer: string;
  getCertified: string;
  selectStartAssessment: string;
  thankYouForReview: string;
  viewBadgeCredly: string;
  completed: string;
};

export type SettingsType = {
  logolink: string;
  toplinks: SettingsLink[];
  navigation: SettingsLink[];
  footerlinks: SettingsLink[];
  dictionary: Dictionary;
};

type SettingsCollection = {
  'en-US': SettingsType;
  de: SettingsType;
};

const i18n: SettingsCollection = {
  'en-US': enUS,
  de: deDE,
};

export default i18n;
