/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import { mqFrom } from 'styles/styles';
import { LANGUAGES } from 'utils/constants';
import i18n from 'utils/i18n';
import { GlobalContext, Themes } from '../context/global-context';
import Button from './button';

const SettingsButtons: React.FC = () => {
  const { theme, setTheme } = useContext(GlobalContext);
  const { locale, asPath } = useRouter();
  const { dictionary } = i18n[(locale as AcceptedLocales) || 'en-US'];

  const [showThemes, setShowThemes] = useState(false);
  const [showLanguages, setShowLanguages] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setShowThemes(false);
    setShowLanguages(false);
  });

  const themes = [
    { key: 'light', label: dictionary.settingsThemeLight },
    { key: 'dark', label: dictionary.settingsThemeDark },
    { key: 'high-contrast', label: dictionary.settingsThemeHighContrast },
  ];

  return (
    <StyledSettingsButtons className="settings-buttons">
      <ul ref={ref}>
        <li>
          <Button
            mode="transparent"
            size="small"
            iconName="sun"
            aria-controls="theme-menu"
            aria-expanded={showThemes ? 'true' : 'false'}
            data-bi-name="theme"
            onClick={() => {
              setShowThemes(!showThemes);
              setShowLanguages(false);
            }}
          >
            <span>{dictionary.settingsTheme}</span>
          </Button>
          {/* <Button
            mode="transparent"
            size="small"
            iconName="print"
            onClick={() => {
              window.print();
            }}
          >
            <span>{dictionary.settingsPrint}</span>
          </Button> */}
          {showThemes && (
            <div className="popup show">
              <ul>
                {themes.map((t) => (
                  <li
                    onClick={() => {
                      localStorage.setItem('continia.theme', t.key);
                      setTheme(t.key as Themes);
                    }}
                    key={t.key}
                    className={t.key === theme ? 'active' : ''}
                  >
                    {t.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
        <li>
          <Button
            mode="transparent"
            size="small"
            iconName="globe"
            aria-controls="language-menu"
            aria-expanded={showLanguages ? 'true' : 'false'}
            data-bi-name="language"
            onClick={() => {
              setShowLanguages(!showLanguages);
              setShowThemes(false);
            }}
          >
            <span>{dictionary.settingsLanguage}</span>
          </Button>
          {showLanguages && (
            <div className="popup show">
              <ul>
                {LANGUAGES.map((l) => (
                  <li key={l.key} className={locale === l.key ? 'active' : ''}>
                    <Link href={asPath} locale={l.key}>
                      <a>{l.label}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      </ul>
    </StyledSettingsButtons>
  );
};

const StyledSettingsButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  ${mqFrom.desktop} {
    justify-content: flex-end;
    align-items: stretch;
  }

  > ul {
    display: flex;

    > li {
      display: flex;
      position: relative;
      cursor: pointer;

      .popup {
        display: none;
        position: absolute;
        bottom: 30px;
        margin-bottom: 10px;
        position: absolute;
        width: auto;
        white-space: nowrap;
        z-index: 1000;
        background-color: var(--body-background);
        border: 1px solid var(--border);
        box-shadow: 0 6.4px 14.4px 0 var(--box-shadow-medium), 0 1.2px 3.6px 0 var(--box-shadow-light);

        &.show {
          display: block;
        }

        ul {
          padding: 0.5rem !important;
          display: block;
          li {
            display: block;
            border-radius: 2px;
            font-size: 0.875rem;
            padding-bottom: calc(0.375em - 1px);
            padding-left: 0.75em;
            padding-right: 0.75em;
            padding-top: calc(0.375em - 1px);
            // text-align: center;
            white-space: nowrap;

            &:active,
            &.active {
              border: 1px solid var(--primary-base);
            }
            &:hover,
            &:focus {
              background-color: var(--body-background-medium);
              color: var(--text);
            }
          }
        }
      }
    }
  }

  button {
    position: relative;
    align-items: center;
    border: none;
    font-weight: 400;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    line-height: 1.5;
    padding: calc(0.375em - 1px) 0.75em;
    text-align: center;
    text-decoration: none !important;
    transition: all 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
    white-space: nowrap;
    font-weight: 600;
    z-index: 1001;

    &[aria-expanded='true']:after,
    &[aria-expanded='true']:before {
      border-color: transparent;
      border-style: outset outset solid;
      content: ' ';
      display: block;
      height: 0;
      left: calc(50% - 8px);
      pointer-events: none;
      position: absolute;
      bottom: 25px;
      width: 0;
    }

    &[aria-expanded='true']:after {
      border-top-color: var(--body-background);
      border-width: 7px;
      margin-bottom: 2px;
      margin-left: 1px;
    }

    &[aria-expanded='true']:before {
      border-top-color: var(--border);
      border-width: 8px;
    }

    &:hover {
      background: var(--body-background-medium);
    }
    &:active {
      background: var(--body-background-dark);
    }
  }
`;

export default SettingsButtons;
