/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from 'styled-components';
import { mqFrom } from 'styles/styles';
import cn from 'utils/cn';

export type ModalProps = {
  visible: boolean;
  onClose: () => void;
  headline?: string;
  description?: string;
  fullScreen?: boolean;
  img?: React.ReactNode;
  center?: boolean;
};

export const Modal: React.FC<ModalProps> = ({
  visible,
  onClose,
  children,
  headline,
  description,
  fullScreen,
  img,
  center,
}) => {
  if (!visible) {
    return null;
  }

  return (
    <StyledModal>
      <div className="modal-background" onClick={onClose} />
      <div className={cn('modal-content', fullScreen ? 'full-screen' : '', center && 'modal-content-with-xp')}>
        <>
          <button className="modal-close" aria-label="Close" onClick={onClose}></button>
          <div
            className={cn(
              'modal-card',
              img && 'modal-card-with-image',
              center && 'modal-card-center',
              fullScreen && 'modal-card-full-screen'
            )}
          >
            {img}
            <div className="modal-card-inner">
              {headline && <h2>{headline}</h2>}
              {description && <h2>{description}</h2>}
              {children}
            </div>
          </div>
        </>
      </div>
    </StyledModal>
  );
};

const StyledModal = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  position: fixed;
  z-index: 1050;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  .modal-background {
    background-color: rgba(0, 0, 0, 0.5);
    outline-color: var(--text);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .modal-close {
    user-select: none;
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    z-index: 1050;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 290486px;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0;
    height: 20px;
    max-height: 20px;
    max-width: 20px;
    min-height: 20px;
    min-width: 20px;
    outline: none;
    vertical-align: top;
    width: 20px;

    &::before {
      background-color: currentColor;
      border: 1px solid transparent;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      width: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform-origin: center center;
    }

    &::after {
      background-color: currentColor;
      border: 1px solid transparent;
      content: '';
      display: block;
      left: 50%;
      height: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform-origin: center center;
    }
  }

  .modal-content {
    margin: 0 auto;
    position: relative;
    max-width: calc(100vw - 40px);
    width: auto;

    &.full-screen {
      max-width: 95vw;

      ${mqFrom.tablet} {
        max-width: 80vw;
        max-height: 90vh;
      }

      .modal-card {
        width: 100%;
      }

      .big-image {
        span {
          ${mqFrom.tablet} {
            max-height: calc(90vh - 6rem);
          }
        }
      }
    }

    ${mqFrom.tablet} {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      max-height: 100%;
      overflow-y: auto;
      position: relative;
    }

    &.modal-content-with-xp {
      ${mqFrom.tablet} {
        max-width: 480px;
      }
    }
  }

  .modal-card {
    background-color: var(--body-background);
    border: 1px solid var(--border-white-high-contrast);
    box-shadow: 0 1.6px 3.6px 0 var(--box-shadow-medium), 0 0.3px 0.9px 0 var(--box-shadow-light);
    outline-color: var(--text);
    border-radius: 6px;
    overflow-y: auto;
    position: relative;
    padding: 1rem;
    width: auto;
    max-width: 600px;

    ${mqFrom.tablet} {
      padding: 1.5rem !important;
      overflow: hidden;
      margin: 0 auto;
    }

    &.modal-card-center {
      .modal-card-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      svg {
        max-width: 180px;
        margin: 0.5rem 0 1.5rem;
      }
    }

    &.modal-card-full-screen {
      max-width: 100%;
    }

    &.modal-card-with-image {
      padding: 0 !important;
      border: unset;
      display: flex;
      position: relative;

      ${mqFrom.tablet} {
        display: flex;
      }

      .modal-card-inner {
        padding: 1rem;

        ${mqFrom.tablet} {
          padding: 1.5rem !important;
        }
      }
    }

    h2 {
      margin-top: 0;
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1.125;
      text-align: left;
      margin-bottom: 1.5rem;
      /* margin-right: 1rem; */
    }

    p {
      margin: 1rem 0;
    }

    .buttons {
      display: flex;
      gap: 0.6rem;
    }
  }

  .modal-image {
    height: auto;
    width: 100%;
    display: none;

    ${mqFrom.tablet} {
      width: 250px;
      display: flex;
    }

    img {
      width: auto;
      height: 100%;
      border-radius: 0;
    }
  }
`;

export default Modal;
