/* eslint-disable @typescript-eslint/no-empty-function */
import Head from 'next/head';
import Header from 'components/header';
import Footer from 'components/footer';
import React from 'react';
import SubNavigation from 'components/sub-navigation';
import Breadcrumb from 'components/breadcrumb';
import { useRouter } from 'next/router';
import Link from 'next/link';
import classNames from 'classnames';

type LayoutProps = {
  breadcrumb: BreadcrumbItem[];
  title: string;
  description: string;
  mainClassName?: string;
  hidden?: boolean;
};

const Layout: React.FC<LayoutProps> = ({ children, breadcrumb, title, description, mainClassName, hidden = false }) => {
  const { isPreview } = useRouter();

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {hidden && <meta name="robots" content="noindex" />}

        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,  maximum-scale=1.0, user-scalable=no, shrink-to-fit=no"
        />
        <meta name="last-build" content={new Date().toISOString()} />
      </Head>
      <Header />
      <SubNavigation />
      {breadcrumb.length > 0 && (
        <div className="breadcrumb outer-padding">
          <Breadcrumb breadcrumb={breadcrumb} />
        </div>
      )}
      {isPreview && (
        <div className="preview-warning">
          <p>You are in preview mode</p>
          <Link href={`/api/editor/clear-preview-data`} prefetch={false}>
            <a>Clear Cookies</a>
          </Link>
        </div>
      )}
      <main id="main-content" className={classNames(mainClassName)}>
        {children}
      </main>
      <Footer />
    </>
  );
};
export default Layout;
