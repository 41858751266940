import { WORD_PR_MINUTE } from './constants';
import { Dictionary } from './i18n';
import JWT from 'jsonwebtoken';

export const isExternalLink = (link: string): boolean => {
  if (!link) {
    return false;
  }
  const isExtLink =
    link.trim().toLowerCase().startsWith('https://') ||
    link.trim().toLowerCase().startsWith('http://') ||
    link.trim().toLowerCase().startsWith('mailto:') ||
    link.trim().toLowerCase().startsWith('tel:');
  return isExtLink;
};

export const sleep = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const round = (num: number): number => Math.round(num * 100) / 100;

export const isEmail = (email: string): boolean => {
  const trimmedEmail = (String(email) || '').trim().toLowerCase();
  const emailRegExp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  const isValid = emailRegExp.test(trimmedEmail);
  return isValid;
};

export const calcMinutesToRead = (data: string): number => {
  // Counting word
  const words = data.split(/[\n\s]/g).filter((w) => !!w).length;

  // Calculate time to read
  const minutes = Math.ceil(words / WORD_PR_MINUTE);

  return minutes;
};

// Helper to format minutes to HH:MM
export const formatMinutesToRead = (minutes: number, dictionary: Dictionary): string => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  const hoursStr = hours > 0 ? `${hours} ${dictionary.minsToReadHour} ` : '';
  const minutesStr = minutesLeft > 0 ? `${minutesLeft} ${dictionary.minsToReadMin}` : '';
  return `${hoursStr}${minutesStr}`.trim();
};

export const uniq = (arr: string[]): string[] => {
  return [...new Set(arr)];
};

export const checkAccessToken = (accessToken: string): { success: boolean; reason?: string } => {
  if (!accessToken) {
    return { success: false, reason: 'No access token' };
  }
  if (JWT.decode(accessToken).exp < Date.now() / 1000) {
    return { success: false, reason: 'Access token expired' };
  }
  if (JWT.decode(accessToken).exp >= Date.now() / 1000) {
    return { success: true, reason: '' };
  }
  return { success: false, reason: 'Unknown error' };
};

// helper to sort array by key
export const sortByKey = (key: string) => (a, b) => {
  if ((a[key] || 0) < (b[key] || 0)) return -1;
  if ((a[key] || 0) > (b[key] || 0)) return 1;
  return 0;
};

export const numToLetter = (num: number): string => {
  return String.fromCharCode(num + 65);
};
