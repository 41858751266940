import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import i18n from 'utils/i18n';
import Button from './button';
import Icon from './icon';

type SearchbarProps = {
  className?: string;
  compact?: boolean;
  onSearch?: () => void;
};

const Searchbar: React.FC<SearchbarProps> = ({ className = '', onSearch = () => null, compact = false }) => {
  const { locale, query: qs, push } = useRouter();
  const { dictionary } = i18n[(locale as AcceptedLocales) || 'en-US'];
  const q = qs.q || '';
  const [query, setQuery] = useState((!compact && decodeURIComponent(q as string)) || '');

  useEffect(() => {
    setQuery((qs.q as string) || '');
  }, [qs.q]);

  const searchUrl = `/search?q=${encodeURIComponent(query)}`;

  return (
    <StyledSearchbar className={`search-form ${className} ${compact ? 'compact' : 'full'}`}>
      <div className="search-field">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            push(searchUrl);
            onSearch();
          }}
        >
          <input
            type="search"
            className="input"
            placeholder={dictionary.searchPlaceholder}
            autoComplete="off"
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
          {compact ? (
            <Icon name="zoom" />
          ) : (
            <Button mode="primary" htmlType="submit" iconName="zoom">
              {dictionary.searchBtn}
            </Button>
          )}
        </form>
      </div>
    </StyledSearchbar>
  );
};

const StyledSearchbar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.full {
    justify-content: stretch;
    max-width: 800px;

    .search-field {
      width: 100%;
      form {
        display: flex;
        input {
          width: 100%;
          flex: 1 1 0;
          margin-right: 15px;
        }
      }
    }
  }

  &.compact {
    .search-field {
      position: relative;

      input {
        padding-left: 2.25em;
        border-radius: 2px;
        font-size: 0.875rem;
      }

      .icon {
        position: absolute;
        left: 10px;
        top: 10px;
        font-size: 0.875rem;
        color: var(--primary-base);
      }
    }
  }
`;

export default Searchbar;
