import Link from 'next/link';
import { useState } from 'react';
import styled from 'styled-components';
import Icon from './icon';
import Searchbar from './searchbar';
import { useRouter } from 'next/router';
import i18n, { SettingsType } from 'utils/i18n';
import AvatarMenu from './avatar-menu';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from 'auth/config';
import Button from './button';

export type MobileNavigationProps = {
  setShowNavigation: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MobileNavigation: React.FC<MobileNavigationProps> = ({ setShowNavigation }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [mobileSubItems, setMobileSubItems] = useState(null);
  const [mobileHeader, setMobileHeader] = useState(null);
  const { locale } = useRouter();
  const { dictionary, navigation } = i18n[(locale as AcceptedLocales) || 'en-US'] as SettingsType;
  const { instance } = useMsal();

  return (
    <StyledMobileNavigation className="mobile-drawer">
      <div className="inner">
        <div className="mobile-header">
          {showSearch ? (
            <>
              <Searchbar
                onSearch={() => {
                  setShowNavigation(false);
                }}
              />
              <button
                type="button"
                className="cancel"
                onClick={() => {
                  setShowSearch(false);
                }}
              >
                <span>{dictionary.cancel}</span>
              </button>
            </>
          ) : (
            <>
              <h3>{dictionary.shortName}</h3>
              <div className="buttons">
                <button
                  type="button"
                  className="search"
                  onClick={() => {
                    setShowSearch(true);
                  }}
                >
                  <Icon name="zoom" />
                </button>
                <button
                  type="button"
                  className="close"
                  onClick={() => {
                    setShowNavigation(false);
                  }}
                >
                  <span />
                  <span />
                </button>
              </div>
            </>
          )}
        </div>

        <nav className={`mobile-nav ${mobileSubItems ? 'sub' : 'root'}`}>
          <ul>
            <li>
              {mobileHeader ? (
                <button
                  type="button"
                  className="subheader"
                  onClick={() => {
                    setMobileHeader(null);
                    setMobileSubItems(null);
                  }}
                >
                  <Icon name="chevron-left" />
                  <span>{mobileHeader}</span>
                </button>
              ) : (
                <div className="header">
                  <h2>{dictionary.shortName}</h2>
                </div>
              )}
            </li>
            {(mobileSubItems || navigation).map((item) => (
              <li key={item.name}>
                {item.href && !item.items ? (
                  <Link href={item.href}>
                    <a>
                      <span>{item.name}</span>
                    </a>
                  </Link>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setMobileHeader(item.name);
                      setMobileSubItems(item.items);
                    }}
                  >
                    <span>{item.name}</span>
                    <Icon name="chevron-right" />
                  </button>
                )}
              </li>
            ))}
          </ul>
        </nav>

        <div className="spacer"></div>

        <div className="bottom">
          <AuthenticatedTemplate>
            <AvatarMenu mobile />
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <Button mode="transparent" className="login-button" onClick={() => instance.loginRedirect(loginRequest)}>
              <span>{dictionary.login}</span>
            </Button>
          </UnauthenticatedTemplate>
        </div>
      </div>
    </StyledMobileNavigation>
  );
};

export const StyledMobileNavigation = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  z-index: 1000;

  .inner {
    position: absolute;
    max-width: 100% !important;
    max-height: 100vh !important;
    width: 320px !important;
    top: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    right: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--body-background);

    .buttons {
      align-items: stretch;
    }

    .mobile-header {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      border-bottom: 1px solid var(--border);
      height: 54px;
      min-height: 54px;
      h3 {
        padding: 1rem;
        margin: 0;
        font-size: 100%;
      }

      .search-form {
        max-width: 100%;

        .search-field {
          button {
            display: none;
          }

          input {
            margin-left: 1rem;
          }
        }
      }

      .search {
        background: none;
        border: none;
      }

      .cancel {
        background: none;
        border: none;
        margin-right: 1rem;
        font-size: 0.875rem;
      }

      button {
        background-color: var(--body-background-medium);
        color: var(--text);
        border: none;
        border-left: 1px solid var(--border);
        padding: 0 1rem;
        &.close {
          span {
            display: block;
            width: 15px;
            height: 2px;
            background-color: var(--text);

            &:first-of-type {
              transform: translateY(1px) rotate(45deg);
            }
            &:last-of-type {
              transform: translateY(-1px) rotate(-45deg);
            }
          }
        }
      }
    }

    nav.mobile-nav {
      // display: flex;
      ul {
        li {
          a,
          button,
          .header {
            color: var(--text);

            padding: 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            align-self: stretch;
            flex-shrink: 0;
            flex-grow: 0;
            min-width: 54px;
            background-color: transparent;
            border: none;
            text-decoration: none !important;
            white-space: nowrap;
            font-size: 0.875rem;
            line-height: normal;
            cursor: pointer;
            outline-offset: -0.125rem !important;

            &.subheader {
              justify-content: flex-start;
              font-weight: 600;
              border-bottom: var(--border);

              span {
                padding-left: 7px;
              }
            }
          }

          .header {
            cursor: default;
            h2 {
              font-weight: 600;
              font-size: 1rem;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .spacer {
    flex: 1 1 0;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    border-top: 1px solid var(--border);
    padding: 1rem;
  }
`;

export default MobileNavigation;
