/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import { mqFrom } from 'styles/styles';
import i18n, { SettingsType } from 'utils/i18n';
import Icon from './icon';

const SubNavigation: React.FC = () => {
  const { locale, asPath } = useRouter();
  const { dictionary, navigation } = i18n[(locale as AcceptedLocales) || 'en-US'] as SettingsType;
  const [expanded, setExpanded] = useState(null);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setExpanded(null);
  });

  return (
    <StyledSubNavigation className="sub-navigation outer-padding">
      <ul ref={ref}>
        <li>
          <Link href="/">
            <a className="title">
              <span>{dictionary.shortName}</span>
            </a>
          </Link>
        </li>
        {navigation.map((item, index) => (
          <li
            key={item.name}
            onClick={() => {
              if (expanded !== index) {
                setExpanded(index);
              } else {
                setExpanded(null);
              }
            }}
          >
            {item.href && !item.items ? (
              <Link href={item.href}>
                <a className={item.href === asPath ? 'is-active' : ''}>
                  <span>{item.name}</span>
                </a>
              </Link>
            ) : (
              <button type="button" className={expanded === index ? 'expanded' : ''}>
                <span>{item.name}</span>
                {expanded === index ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}
              </button>
            )}
            {expanded === index && item.items && (
              <ul className="dropdown">
                {item.items.map((subitem) => (
                  <li key={subitem.name}>
                    <Link href={subitem.href}>
                      <a className={subitem.href.startsWith(asPath) ? 'is-active' : ''}>{subitem.name}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </StyledSubNavigation>
  );
};

const StyledSubNavigation = styled.nav`
  display: none;
  position: relative;
  align-items: stretch;
  height: 54px;
  min-height: 54px;
  border-bottom: 1px solid var(--border);

  ${mqFrom.mobile} {
    display: flex;
    // display: none;
  }

  ul {
    display: flex;
    align-items: stretch;

    li {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      position: relative;

      a,
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
        flex-grow: 0;
        min-width: 54px;
        padding: 0 0.5rem;
        background-color: transparent;
        color: var(--text);
        border: none;
        text-decoration: none !important;
        white-space: nowrap;
        font-size: 0.875rem;
        line-height: normal;
        cursor: pointer;
        outline-offset: -0.125rem !important;

        span {
          position: relative;
        }

        &.expanded {
          background-color: var(--body-background-medium);
        }

        .icon {
          margin-left: auto;
          padding-left: 0.25rem;
          font-size: 0.75rem;
          color: var(--text-subtle);
        }

        &.title {
          padding-left: 0;
          padding-bottom: 0.25rem !important;
          span {
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.125;
          }
        }

        &.is-active > span:after,
        &:hover > span:after {
          content: '';
          display: block;
          position: absolute;
          border-bottom: 2px solid var(--text);
          left: 0;
          right: 0;
          bottom: -0.125rem;
        }

        &.is-active,
        &:active,
        &:visited {
          color: var(--text);
        }
      }
      .dropdown {
        position: absolute;
        width: auto;
        left: 0;
        top: 100%;
        white-space: nowrap;
        z-index: 200;
        border: 1px solid var(--border);
        background-color: var(--body-background-medium);
        box-shadow: 0 6.4px 14.4px 0 var(--box-shadow-medium);
        display: flex;
        flex-direction: column;

        > li {
          display: flex;
          align-items: center;
          justify-content: stretch;
          flex-shrink: 0;

          a {
            padding: 0.5rem 1rem;
            flex: 1;
            justify-content: flex-start;

            &:hover {
              background-color: var(--body-background-dark);
              text-decoration: underline !important;
            }
          }
        }
      }
    }
  }
`;

export default SubNavigation;
