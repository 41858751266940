/* eslint-disable @next/next/no-img-element */
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from 'auth/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useContext } from 'react';
import styled from 'styled-components';
import { mqFrom, mqUntil } from 'styles/styles';
import i18n, { SettingsType } from 'utils/i18n';
import AvatarMenu from './avatar-menu';
import Button from './button';
import MobileNavigation from './mobile-navigation';
import Searchbar from './searchbar';
import { GlobalContext } from '../context/global-context';

export const Header: React.FC = () => {
  const { instance } = useMsal();
  const { theme } = useContext(GlobalContext);
  const [showNavigation, setShowNavigation] = useState(false);

  const { locale } = useRouter();
  const { dictionary, toplinks } = i18n[(locale as AcceptedLocales) || 'en-US'] as SettingsType;

  return (
    <StyledHeader
      role="banner"
      itemScope
      theme={theme}
      itemType="http://schema.org/Organization"
      className="outer-padding"
      id="header"
    >
      <Link href="/">
        <a itemProp="url" aria-label="Continia Docs" className="logo">
          <div className="nav-bar-logo" role="presentation" aria-hidden="true" itemProp="logo" itemScope>
            <svg className={theme} xmlns="http://www.w3.org/2000/svg" viewBox="65.08 74.83 685.4 100.83">
              <path
                className="st0"
                d="M236.18,112.68c-17.37,0-19.06,10.45-19.06,24.57s1.7,24.85,19.06,24.85h28.95v12.71h-33.04c-25.42,0-32.06-14.97-32.06-37.56s6.63-37.28,32.06-37.28h31.34v12.71h-27.25Z"
              ></path>
              <path
                className="st0"
                d="M356.67,137.25c0,29.94-12.99,38.41-39.82,38.41s-39.82-8.47-39.82-38.41,12.99-38.12,39.82-38.12,39.82,8.47,39.82,38.12Zm-39.82,25.7c16.95,0,22.74-7.35,22.74-25.7s-5.79-25.42-22.74-25.42-22.73,7.06-22.73,25.42,5.79,25.7,22.73,25.7Z"
              ></path>
              <path
                className="st0"
                d="M372.39,112.68c0-8.47,4.24-12.71,12.71-12.71h26.26c16.95,0,29.09,5.79,29.09,25.56v49.28h-16.52v-44.9c0-11.3-4.37-17.22-15.67-17.22h-15.11c-2.83,0-4.24,1.41-4.24,4.23v57.9h-16.52v-62.13Z"
              ></path>
              <path
                className="st0"
                d="M494.02,174.81c-16.95,0-27.54-6.07-27.54-24.43v-37.7h-10.31v-12.71h10.31v-24.01h16.52v24.01h21.32v12.71h-21.32v34.31c0,11.3,5.93,15.11,17.23,15.11h4.09v12.71h-10.31Z"
              ></path>
              <path
                className="st0"
                d="M522.33,90.09h0c0-8.42,6.83-15.25,15.25-15.25h1.27s0,15.25,0,15.25h-16.52Zm0,9.88h16.52v74.84h-16.52V99.97Z"
              ></path>
              <path
                className="st0"
                d="M558.92,112.68c0-8.47,4.24-12.71,12.71-12.71h26.27c16.95,0,29.09,5.79,29.09,25.56v49.28h-16.52v-44.9c0-11.3-4.37-17.22-15.67-17.22h-15.11c-2.83,0-4.24,1.41-4.24,4.23v57.9h-16.52v-62.13Z"
              ></path>
              <path
                className="st0"
                d="M647.09,90.09v-15.25h16.52c0,8.42-6.83,15.25-15.25,15.25h-1.27Zm0,9.88h16.52v74.84h-16.52V99.97Z"
              ></path>
              <path
                className="st0"
                d="M729.43,99.97c14.12,0,21.04,9.32,21.04,22.03v40.1c0,8.47-4.24,12.71-12.71,12.71h-37.7c-12.71,0-18.64-7.77-18.64-23.3,0-14.12,5.93-23.3,18.64-23.3h33.89v-4.8c0-7.06-3.39-10.73-10.45-10.73h-36.57v-12.71h42.5Zm-23.02,40.95c-5.65,0-7.9,3.53-7.9,10.59s2.26,10.59,7.9,10.59h23.3c2.83,0,4.24-1.41,4.24-4.23v-16.95h-27.54Z"
              ></path>
              <path className="st0" d="M118.68,125.39v49.47h-53.59c0-27.32,22.15-49.47,49.47-49.47h4.12Z"></path>
              <path className="st0" d="M118.68,125.39v-49.47h53.59c0,27.32-22.15,49.47-49.47,49.47h-4.12Z"></path>
            </svg>
          </div>
        </a>
      </Link>
      <nav className="nav-bar" role="navigation" aria-label="Global">
        <ul>
          {toplinks.map((link, index) => (
            <li key={link.href} {...(index === 0 && { className: 'has-spacing' })}>
              <a
                href={link.href}
                {...(index === 0 && { className: 'title' })}
                {...(link.href === '/' && { className: 'is-active' })}
              >
                <span>{link.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <span className="nav-bar-spacer is-hidden-mobile" />

      <div className="desktop-right">
        <Searchbar compact className="is-hidden-mobile" />
        <AuthenticatedTemplate>
          <AvatarMenu />
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Button
            mode="transparent"
            className="login-button"
            onClick={() => {
              instance
                .loginRedirect(loginRequest)
                .then((res) => {
                  console.log('@login success', res);
                })
                .catch((err) => {
                  console.log('@login error', err);
                });
            }}
          >
            <span>{dictionary.login}</span>
          </Button>
        </UnauthenticatedTemplate>
      </div>

      <div className="mobile-right">
        <a href="https://docs.continia.com" className="mobile-title">
          <span>Docs</span>
        </a>

        <button
          type="button"
          className="burger"
          data-toggle="collapse"
          onClick={() => setShowNavigation((val) => !val)}
        >
          <span className="icon-bar" />
          <span className="icon-bar" />
          <span className="icon-bar" />
        </button>
      </div>

      {showNavigation && <MobileNavigation setShowNavigation={setShowNavigation} />}
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 54px;

  ${mqUntil.mobile} {
    padding-right: 0 !important;
  }

  border-bottom: 1px solid var(--border);

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-bar-logo {
      display: flex;
      justify-content: center;
    }

    svg {
      height: 18.5px;
      width: auto;

      &.light {
        fill: #052975;
      }

      &.dark,
      &.high-contrast {
        fill: #ffffff;
      }
    }
  }

  nav.nav-bar {
    display: none;
    align-items: stretch;

    ${mqFrom.mobile} {
      display: flex;
    }

    ul {
      display: flex;
      align-items: stretch;

      li {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        &.has-spacing {
          margin: 0 0.5rem;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: stretch;
          flex-shrink: 0;
          flex-grow: 0;
          min-width: 54px;
          padding: 0 0.5rem;
          background-color: transparent;
          color: var(--text);
          border: none;
          text-decoration: none !important;
          white-space: nowrap;
          font-size: 0.875rem;
          line-height: normal;
          cursor: pointer;
          outline-offset: -0.125rem !important;

          span {
            position: relative;
          }

          &.title {
            span {
              font-size: 1.125rem;
              font-weight: 600;
              line-height: 1.125;
            }
            &:before {
              content: '';
              display: inline-block;
              height: 1.5rem;
              border-left: 2px solid var(--text-subtle);
              margin-left: 0.5rem;
              margin-right: 1rem;
            }
          }

          &.is-active > span:after,
          &:hover > span:after {
            content: '';
            display: block;
            position: absolute;
            border-bottom: 2px solid var(--text);
            left: 0;
            right: 0;
            bottom: -0.125rem;
          }

          &.is-active,
          &:active,
          &:visited {
            color: var(--text);
          }
        }
      }
    }
  }

  .nav-bar-spacer {
    display: none;
    flex-grow: 1;
    flex-shrink: 1;
    ${mqFrom.mobile} {
      display: block;
    }
  }
  .desktop-right {
    justify-content: center;
    align-items: center;
    display: none;
    gap: 1rem;

    ${mqFrom.mobile} {
      display: flex;
    }
  }

  .login-button {
    align-self: center;
    border: 1px solid transparent;
    border-radius: 2px;
    color: var(--primary-base);
    height: 2.25em;
    line-height: 1.5;
    padding-bottom: calc(0.375em - 1px);
    padding-left: 0.75em;
    padding-right: 0.75em;
    padding-top: calc(0.375em - 1px);
    position: relative;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .mobile-right {
    display: flex;
    justify-content: center;
    align-items: center;

    ${mqFrom.mobile} {
      display: none;
    }
  }

  button.burger {
    border: none;
    outline-offset: -0.125rem !important;
    cursor: pointer;
    display: block;
    height: 100%;
    position: relative;
    width: 54px;

    span {
      border-top: 1px solid currentColor;
      display: block;
      height: 1px;
      left: calc(50% - 8px);
      position: absolute;
      transform-origin: center;
      transition-duration: 86ms;
      transition-property: background-color, opacity, transform;
      transition-timing-function: ease-out;
      width: 16px;
      height: 1.5px;

      &:first-child {
        top: calc(50% - 6px);
      }
      &:nth-child(2) {
        top: calc(50% - 1px);
      }
      &:nth-child(3) {
        top: calc(50% + 4px);
      }
    }
  }

  .mobile-title {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex-shrink: 0;
    flex-grow: 0;
    min-width: 54px;
    padding: 0 0.5rem;
    background-color: transparent;
    color: var(--text);
    border: none;
    text-decoration: none !important;
    white-space: nowrap;
    cursor: pointer;
    outline-offset: -0.125rem !important;

    font-weight: 600;
    padding-bottom: 0.25 rem;
    padding-left: 0.5 rem;
  }
`;

export default Header;
