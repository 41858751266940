/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import Loader from 'components/load-spinner';
import Link from 'next/link';
import { isExternalLink } from 'utils/helpers';
import Icon, { iconNames } from './icon';
import Modal from './modal';

type LinkProps = {
  prefetch?: boolean;
  href: string;
  locale?: string;
  target?: string;
};

export type ButtonProps = {
  size?: 'default' | 'large' | 'small';
  mode?: 'default' | 'primary' | 'secondary' | 'transparent' | 'danger';
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  // icon?: any; // eslint-disable-line
  alignIcon?: 'right' | 'left';
  htmlType?: 'submit' | 'reset' | 'button';
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  iconName?: iconNames;
  style?: React.CSSProperties;
  children?: React.ReactNode | string;
  confirmText?: React.ReactNode | string;
  linkProps?: LinkProps;
};

export const Button: React.FC<ButtonProps> = ({
  children,
  size = 'default',
  mode = 'default',
  onClick,
  onKeyPress,
  // icon: Icon,
  alignIcon = 'left',
  htmlType = 'button',
  disabled,
  loading,
  className,
  confirmText,
  iconName,
  linkProps,
  ...props
}) => {
  const iconSizes = {
    default: 20,
    large: 30,
    small: 18,
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const IconElem = iconName ? <Icon size={size} name={iconName} className={`icon-${alignIcon}`} /> : null;

  const linkAddon: Record<string, string> = {};
  if (isExternalLink(linkProps?.href || '')) {
    linkAddon.href = linkProps?.href || '';
  }
  if (linkProps?.target && linkProps.target) {
    linkAddon.target = linkProps.target;
  }

  return (
    <>
      {confirmText && (
        <Modal visible={showConfirmModal} headline={confirmText as string} onClose={() => setShowConfirmModal(false)}>
          <div className="modal-buttons">
            <Button size="small" onClick={() => setShowConfirmModal(false)}>
              Cancel
            </Button>
            <Button
              size="small"
              onClick={(e) => {
                setShowConfirmModal(false);
                if (onClick) {
                  onClick(e);
                }
              }}
            >
              Okay
            </Button>
          </div>
        </Modal>
      )}

      {linkProps ? (
        <Link {...linkProps}>
          <StyledAnchor
            className={classNames(
              `btn`,
              className || '',
              `size-${size}`,
              `mode-${mode}`,
              { disabled: !!disabled },
              { empty: !children },
              { loading: !!loading }
            )}
            onKeyPress={(e) => {
              if (confirmText) {
                setShowConfirmModal(true);
              } else if (onKeyPress) {
                onKeyPress(e);
              }
            }}
            onClick={(e) => {
              if (confirmText) {
                setShowConfirmModal(true);
              } else if (onClick) {
                onClick(e);
              }
            }}
            {...props}
            {...linkAddon}
          >
            <>
              {alignIcon === 'left' &&
                (loading ? <Loader className={`icon icon-${alignIcon}`} negative size={iconSizes[size]} /> : IconElem)}
              <span className="label">{children}</span>
              {alignIcon === 'right' &&
                (loading ? <Loader className={`icon icon-${alignIcon}`} negative size={iconSizes[size]} /> : IconElem)}
            </>
          </StyledAnchor>
        </Link>
      ) : (
        <StyledButton
          type={htmlType}
          disabled={disabled || loading}
          className={classNames(
            `btn`,
            className || '',
            `size-${size}`,
            `mode-${mode}`,
            { disabled: !!disabled },
            { empty: !children },
            { loading: !!loading }
          )}
          onClick={(e) => {
            e.stopPropagation();
            if (!disabled && !loading) {
              if (confirmText) {
                setShowConfirmModal(true);
              } else if (onClick) {
                onClick(e);
              }
            }
          }}
          {...props}
        >
          <>
            {alignIcon === 'left' &&
              (loading ? <Loader className={`icon icon-${alignIcon}`} size={iconSizes[size]} /> : IconElem)}
            <span className="label">{children}</span>
            {alignIcon === 'right' &&
              (loading ? <Loader className={`icon icon-${alignIcon}`} size={iconSizes[size]} /> : IconElem)}
          </>
        </StyledButton>
      )}
    </>
  );
};

const styleCss = css`
  --button-background: var(--body-background);
  --button-foreground: var(--text);
  --button-border: var(--text-subtle);
  --button-hover-background: var(--hover-invert);
  --button-hover-foreground: var(--hover-base);
  --button-hover-border: var(--hover-base);

  --button-padding: calc(0.375em - 0.5px) 0.75em;
  --button-border-radius: 2px;

  color: var(--button-foreground);
  background: var(--button-background);
  border-radius: var(--button-border-radius);
  transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 1px solid var(--button-border);
  cursor: pointer;
  white-space: nowrap;
  padding: var(--button-padding);
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* gap: 0.4rem; */

  svg[fill]:not([fill='none']),
  svg:not([fill]) {
    fill: var(--button-foreground);
  }

  svg[stroke]:not([stroke='none']),
  svg:not([stroke]) {
    stroke: var(--button-foreground);
  }

  .label {
    margin: 0.15rem 0;
    margin: 0;
    font-weight: 600;
    line-height: 1.5;
  }

  .icon {
    position: relative;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    width: 1.5em;
    margin-top: 0.2rem;

    .loader {
      margin-top: 0;
      margin-left: 0;
    }
  }

  .icon-right {
    margin-right: calc(-0.375em - 1px);
  }
  .icon-left {
    margin-left: calc(-0.375em - 1px);
  }

  &.mode-danger {
    --button-foreground: var(--danger-base);
    --button-border: var(--danger-base);
    --button-hover-foreground: var(--danger-dark);
    --button-hover-border: var(--danger-dark);
    --button-hover-background: var(--danger-background);
  }

  &.mode-primary {
    --button-background: var(--primary-base);
    --button-foreground: var(--primary-invert);
    --button-border: var(--primary-base);
    --button-hover-background: var(--primary-hover);
    --button-hover-foreground: var(--primary-invert);
    --button-hover-border: var(--primary-hover);
  }

  &.mode-secondary {
    --button-background: transparent;
    --button-border: transparent;
    --button-foreground: var(--primary-base);
    --button-hover-background: var(--primary-background);
    --button-hover-foreground: var(--primary-dark);
    --button-hover-border: transparent;
  }

  &.mode-transparent {
    --button-background: transparent;
    --button-border: transparent;
    --button-hover-background: rgba(142, 142, 142, 0.05);
    --button-hover-border: transparent;
  }

  &.disabled {
    pointer-events: none;
    box-shadow: none;
    opacity: 0.5;

    &.mode-primary {
      --button-hover-background: var(--button-background);
      --button-hover-foreground: var(--button-foreground);
      --button-hover-border: var(--button-border);
    }
  }

  &:visited {
    color: var(--button-foreground);
  }

  &.loading {
    pointer-events: none;
    .label {
      margin-left: 0.3rem;
    }
    svg .path {
      stroke: var(--button-foreground);
    }
  }

  &.size-small {
    font-size: 0.875rem;

    .label {
      font-weight: 400;
    }
  }
  &.size-default {
  }

  &.size-large {
    font-size: 1.125rem;
  }

  &.empty {
    .icon-right,
    .icon-left {
      margin-right: 0;
      margin-left: 0;
      transform: scale(1.4);
    }

    &.size-small {
      padding: calc(0.375em - 1px) 0.75em;
      font-size: 0.875rem;
    }
    &.size-default {
      padding: calc(0.375em - 1px) 0.75em;
      font-size: 1rem;
    }

    &.size-large {
      padding: calc(0.375em - 1px) 0.75em;
      font-size: 1.25rem;
    }
  }

  &:hover {
    border-color: var(--button-hover-border);
    background-color: var(--button-hover-background);
    color: var(--button-hover-foreground);
    text-decoration: none;
  }

  &:active {
    opacity: 0.8;
  }

  .load-wrapper {
    --primary-background: var(--button-foreground);
  }
`;

const StyledButton = styled.button`
  ${styleCss}
`;

const StyledAnchor = styled.a`
  ${styleCss}
`;

export default Button;
