/* eslint-disable @next/next/no-img-element */
import { useMsal } from '@azure/msal-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState, useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import i18n, { SettingsType } from 'utils/i18n';

export type AvatarMenuProps = {
  mobile?: boolean;
};

export const AvatarMenu: React.FC<AvatarMenuProps> = ({ mobile = false }) => {
  const { accounts, instance } = useMsal();
  const [open, setOpen] = useState(false);
  const { locale } = useRouter();
  const { dictionary } = i18n[(locale as AcceptedLocales) || 'en-US'] as SettingsType;
  const ref = useRef(null);

  useClickAway(ref, () => {
    setOpen(false);
  });

  const account = accounts[0];
  if (!account) {
    console.log('@@ NO ACCOUNT', accounts);
    return null;
  }

  const name =
    `${(account.idTokenClaims as Record<string, unknown>)?.given_name || ''} ${
      (account.idTokenClaims as Record<string, unknown>)?.family_name || ''
    }`.trim() ||
    account.name ||
    ((account.idTokenClaims as Record<string, unknown>)?.name as string) ||
    ((account.idTokenClaims as Record<string, unknown>)?.display_name as string) ||
    (account.username as string);

  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('');

  return (
    <StyledAvatarMenu ref={ref} className={mobile ? 'mobile' : 'desktop'}>
      <button
        onClick={() => setOpen(!open)}
        className="dropdown-trigger"
        aria-controls="user-menu"
        aria-expanded="false"
        aria-label="Your account"
      >
        {initials ? (
          <span className="initials">{initials}</span>
        ) : (
          <figure className="avatar">
            <img
              alt=""
              data-profile-property="avatarUrl"
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iMTYiIGN5PSIxNiIgcj0iMTYiIGZpbGw9IiNGNEY1RjYiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuMTE4NjUgMjYuNzE2MkM2LjE0MzYgMjIuMTY5NyAxMC43MDE2IDE5IDE2IDE5QzIxLjI5ODMgMTkgMjUuODU2NCAyMi4xNjk3IDI3Ljg4MTMgMjYuNzE2MkMyNC45NTMxIDI5Ljk2MDggMjAuNzE0NyAzMiAxNiAzMkMxMS4yODUzIDMyIDcuMDQ2ODkgMjkuOTYwOCA0LjExODY1IDI2LjcxNjJaTTIzIDEwQzIzIDEzLjg2NiAxOS44NjYgMTcgMTYgMTdDMTIuMTM0IDE3IDguOTk5OTkgMTMuODY2IDguOTk5OTkgMTBDOC45OTk5OSA2LjEzNDAxIDEyLjEzNCAzIDE2IDNDMTkuODY2IDMgMjMgNi4xMzQwMSAyMyAxMFoiIGZpbGw9IiNCOEI5QkEiLz48L3N2Zz4="
            />
          </figure>
        )}
      </button>
      {open && (
        <div className="dropdown-menu" role="menu">
          {/* User info */}
          <div className="user-info">
            {initials ? (
              <span className="initials">{initials}</span>
            ) : (
              <figure className="avatar">
                <img
                  alt=""
                  data-profile-property="avatarUrl"
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iMTYiIGN5PSIxNiIgcj0iMTYiIGZpbGw9IiNGNEY1RjYiLz48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuMTE4NjUgMjYuNzE2MkM2LjE0MzYgMjIuMTY5NyAxMC43MDE2IDE5IDE2IDE5QzIxLjI5ODMgMTkgMjUuODU2NCAyMi4xNjk3IDI3Ljg4MTMgMjYuNzE2MkMyNC45NTMxIDI5Ljk2MDggMjAuNzE0NyAzMiAxNiAzMkMxMS4yODUzIDMyIDcuMDQ2ODkgMjkuOTYwOCA0LjExODY1IDI2LjcxNjJaTTIzIDEwQzIzIDEzLjg2NiAxOS44NjYgMTcgMTYgMTdDMTIuMTM0IDE3IDguOTk5OTkgMTMuODY2IDguOTk5OTkgMTBDOC45OTk5OSA2LjEzNDAxIDEyLjEzNCAzIDE2IDNDMTkuODY2IDMgMjMgNi4xMzQwMSAyMyAxMFoiIGZpbGw9IiNCOEI5QkEiLz48L3N2Zz4="
                />
              </figure>
            )}
            <div className="user-content">
              <h2 className="">
                <span id="displayName" data-profile-property="displayName">
                  {name}
                </span>
              </h2>
              <p>
                <span data-profile-property="upn">{account.username}</span>
              </p>
            </div>
          </div>

          {/* Navigation */}
          <ul>
            <li>
              <Link href="/account">
                <a data-profile-property="profileUrl">{dictionary.profile}</a>
              </Link>
            </li>
            {/* <li>
              <Link href="/account/settings">
                <a data-profile-property="settingsUrl">{dictionary.settings}</a>
              </Link>
            </li> */}
          </ul>
          <div className="border-top">
            <button className="logout-btn" onClick={() => instance.logoutRedirect()}>
              {dictionary.logout}
            </button>
          </div>
        </div>
      )}
    </StyledAvatarMenu>
  );
};

export const StyledAvatarMenu = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Avatar */
  figure {
    width: 36px;
    height: 36px;
    margin: 0;
    img {
      border-radius: 50%;
      display: block;
      height: auto;
      max-height: 100%;
      width: 100%;
      background-color: var(--body-background-medium);
      outline-color: var(--text);
    }
  }

  .initials {
    display: block;
    width: 36px;
    height: 36px;
    margin: 0;
    border-radius: 50%;
    background-color: var(--body-background-medium);
    color: var(--text-subtle);
    font-size: 0.9rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    text-align: center;
    line-height: 36px;
  }

  /* Button */

  .dropdown-trigger {
    background: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    padding: 0;
  }

  /* Dropdown */
  .dropdown-menu {
    margin-top: 12px;
    right: 0;
    white-space: normal;
    background-color: var(--body-background);
    border: 1px solid var(--border);
    box-shadow: 0 6.4px 14.4px 0 var(--box-shadow-medium), 0 1.2px 3.6px 0 var(--box-shadow-light);
    position: absolute;
    top: 100%;
    white-space: nowrap;
    width: auto;
    z-index: 1000;
    padding: 1.25rem;

    &:before {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 10px;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: var(--border);
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      right: 10px;
      margin-left: -7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent;
      border-bottom-color: var(--body-background);
    }

    .user-info {
      align-items: flex-start;
      display: flex;

      figure,
      .initials {
        width: 48px;
        height: 48px;
        line-height: 48px;
        margin-right: 1rem;
        font-size: 1rem;
      }

      .user-content {
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;

        h2 {
          max-width: 200px;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 1.125rem;
          font-weight: 600;
          line-height: 1.125;
          margin-bottom: 0;
          margin-top: 0;
        }

        p {
          margin: 0.2rem 0 0 0;
          max-width: 200px;
          font-weight: inherit;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.875rem;
        }
      }
    }
    ul {
      padding: 1rem 0;
      font-size: 0.875rem;

      li:not(:last-child) {
        padding-bottom: 1rem;
      }
    }

    .border-top {
      border-top: 1px solid var(--border);
      padding-top: 1rem;

      button {
        padding: 0;
        margin: 0;
        border: 0;
        font-size: 0.875rem;
        word-wrap: break-word;
        color: var(--primary-base);
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  &.mobile {
    .dropdown-menu {
      top: auto;
      bottom: 100%;
      left: 0;
      right: auto;
      margin-top: 0;
      margin-bottom: 12px;

      &:before {
        bottom: auto;
        top: 100%;
        right: auto;
        left: 13px;
        transform: rotate(180deg);
      }
      &:after {
        bottom: auto;
        top: 100%;
        right: auto;
        left: 17px;
        transform: rotate(180deg);
      }
    }
  }
`;

export default AvatarMenu;
