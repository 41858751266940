import React from 'react';
import { useRouter } from 'next/router';
import i18n from 'utils/i18n';
import Link from 'next/link';
import styled from 'styled-components';
import { mqUntil, mqFrom } from 'styles/styles';

type BreadcrumbProps = {
  breadcrumb: BreadcrumbItem[];
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb }) => {
  const { locale, asPath } = useRouter();
  const { dictionary } = i18n[locale as AcceptedLocales];
  if (!breadcrumb.length) {
    return <div></div>;
  }
  return (
    <StyledBreadcrumb
      data-bi-name="breadcrumb"
      itemType="http://schema.org/BreadcrumbList"
      role="navigation"
      aria-label="Breadcrumb"
      itemScope
    >
      <ul id="page-breadcrumbs" className="breadcrumbs">
        <li itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
          <Link href="/">
            <a itemProp="item">
              <span itemProp="name">{dictionary.breadcrumbHomeName}</span>
            </a>
          </Link>
          <meta itemProp="position" content="1" />
        </li>
        {breadcrumb.filter(Boolean).map((item, index) => {
          return (
            <li key={`file-${item.id}`} itemProp="itemListElement" itemType="http://schema.org/ListItem" itemScope>
              {item.path === asPath ? (
                <span itemProp="name">{item.label}</span>
              ) : (
                <Link href={item.path}>
                  <a itemProp="item">
                    <span itemProp="name">{item.label}</span>
                  </a>
                </Link>
              )}

              <meta itemProp="position" content={(index + 2).toString()} />
            </li>
          );
        })}
      </ul>
    </StyledBreadcrumb>
  );
};

const StyledBreadcrumb = styled.nav`
  flex-grow: 1 !important;
  width: 100%;
  min-height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mqUntil.mobile} {
    border-bottom: 1px solid var(--border);
    background-color: var(--body-background-medium);
    padding-right: 1rem;
    padding-left: 1rem;
  }

  ul {
    font-size: 0.875rem;
    margin-bottom: 0;
    padding: 0;
    width: 100%;

    li {
      display: inline;

      ${mqFrom.mobile} {
        display: inline-block;
      }

      &:after {
        display: inline;
        margin: 0 6px;
        color: var(--secondary-base);
      }

      &:not(:last-of-type):after {
        content: '/';
      }
    }
  }
`;
export default Breadcrumb;
