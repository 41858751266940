import { useRouter } from 'next/router';
import styled from 'styled-components';
import i18n from 'utils/i18n';
import SettingsButtons from './settings-buttons';
// import TestData from './test-data';

export const Footer: React.FC = () => {
  const { locale } = useRouter();
  const { footerlinks } = i18n[(locale as AcceptedLocales) || 'en-US'];

  return (
    <>
      <StyledFooter>
        <div className="footer-inner outer-padding">
          <SettingsButtons />

          <ul className="footer-links">
            {footerlinks.map((link) => (
              <li key={link.href}>
                <a href={link.href}>
                  <span>{link.name}</span>
                </a>
              </li>
            ))}
            <li>© {new Date().getFullYear()} Continia Software A/S</li>
          </ul>
        </div>
      </StyledFooter>
      {/* <TestData /> */}
    </>
  );
};

const StyledFooter = styled.footer`
  width: 100%;
  margin: 0;
  border-top: 1px solid var(--border-white-high-contrast);

  .footer-inner {
    background-color: var(--secondary-background);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 1rem;
    column-gap: 3rem;
    padding: 48px 16px 53px;

    ul.footer-links {
      font-size: 0.875rem;
      margin-bottom: 0;
      display: flex;
      margin-top: 0;
      text-align: right;

      > li {
        display: inline-block;
        margin: 0.25rem 1.5rem 0 0;
        a {
          color: var(--text);
        }
      }
    }
  }
`;

export default Footer;
